import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Navbar.css';
import { useUser } from '../Context/UserContext';
import Dropdown from './Dropdown';

function Menubar() {
  const user = useUser();
  const navigate = useNavigate();

  //assigning environmental variable to local variable
  const clientid = process.env.REACT_APP_CLIENT_ID;
  const redirectURI = process.env.REACT_APP_REDIRECT_URI;

  //To start oAuth process, logging to twitch account
  const url = `https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${clientid}&redirect_uri=${redirectURI}&scope=user%3Aread%3Aemail&state=12345`;

  const [prevScrollY, setPrevScrollY] = useState(0);
  const [showNavbar, setshowNavbar] = useState(true);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > prevScrollY) {
      setshowNavbar(false);
    } else {
      setshowNavbar(true);
    }

    setPrevScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <>
      <header
        className={`lg:h-[40px] lg:w-full xs:w-full z-50   sticky ${
          showNavbar
            ? 'top-0  transition-all duration-500 ease-in-out'
            : ' transition-all duration-500 ease-in-out translate-y-[-100%] '
        } `}
      >
        <div className='flex bg-navbarBg font-bold '>
          <div
            className='duckIcon flex-none cursor-pointer'
            onClick={() => navigate('/')}
          >
            <img
              className='h-10'
              src='https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/b30266c4-564b-489b-396d-03e8ec746300/public'
              alt='logo'
            />
          </div>
          <div
            className='flex-none xs:py-2 xs:px-2 cursor-pointer'
            onClick={() => navigate('/')}
          >
            <span className='lg:text-xl xs:text-xl '>Dr Scovy</span>
          </div>
          <div className='grow py-2 font-bold lg:text-right  md:text-right sm:text-right xs:text-right '>
            {user.user ? (
              <>
                <div className='justify-items-end px-2   visible lg:hidden'>
                  <Dropdown />
                </div>
                <div className='justify-items-end  lg:justify-between lg:px-6  hidden lg:block  md:px-0 cursor-pointer'>
                  <Link to='/mysteries' className='mx-6'>
                    Solve Mysteries
                  </Link>
                  <Link to='/photos' className='mx-6'>
                    Photos
                  </Link>
                  <Link to='/raids' className='mx-6'>
                    Raid Rewards
                  </Link>
                  <Link to='/feed' className='mx-6'>
                    Feed the Ducks
                  </Link>
                  <Link to='/progress' className='mx-6'>
                    Ducklings
                  </Link>
                  <Link to='/logout' className='mx-6'>
                    Log Out
                  </Link>
                </div>
              </>
            ) : (
              <>
                <div className='justify-items-end px-2   xs:visible sm:visible md:hidden lg:hidden xl:hidden 2xl:hidden'>
                  <Dropdown />
                </div>
                <div className='justify-items-end  lg:justify-between lg:px-6  xs:hidden sm:hidden md:block lg:block xl:block 2xl:block md:px-0 cursor-pointer'>
                  <Link to='/mysteries' className='mx-6'>
                    Solve Mysteries
                  </Link>
                  <Link to='/photos' className='mx-6'>
                    Photos
                  </Link>
                  <Link to='/raids' className='mx-6'>
                    Raid Rewards
                  </Link>
                  <Link
                    to={url}
                    className='text-xl text-light navbar-login font-bold pt-5 px-2 py-2 xs:text-base'
                  >
                    Log In
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </header>
    </>
  );
}

export default Menubar;
